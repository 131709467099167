import React from "react"
import { useI18n } from "next-localization"
import cx from "clsx"

import { COLORS } from "@app/constants"
import { QA_CLASS_NAMES } from "@app/constants"
import { useGallery, useGlobalState } from "@app/contexts"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { AuthClientSignIn } from "@app/features/auth-client/components"
import { AuthClientSignOut } from "@app/features/auth-client/components"
import { useDownloadGallery } from "@app/features/download/hooks"
import { OnboardingHint } from "@app/features/onboarding/components/onboarding-hint"
import { OnboardingPrintBlock } from "@app/features/onboarding/components/onboarding-print-block"
import {
  PRINT_ONBOARDING_PRINT_HINT,
  PRINT_ONDOARDING_IN_VIEW_MARGIN__DESKTOP,
} from "@app/features/onboarding/constants"
import { useOnboarding } from "@app/features/onboarding/store/hooks"
import { useShowProductsModal } from "@app/features/products/hooks/products-modal"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"
import { useSharing } from "@app/features/sharing/hooks"
import { sharesCountIncrement } from "@app/features/statistics"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { useAuthorization } from "@app/features/user/stores"
import { Download, Printer, Share } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import { ActionLike } from "./action-like"

import styles from "./desktop-actions.module.scss"

function DesktopActions({ cover, galleryId, isDownloadEnabled, galleryName }) {
  const i18n = useI18n()
  const downloadGallery = useDownloadGallery(galleryId)
  const $showProductsModal = useShowProductsModal()
  const { isFavoritesSectionActivated, isHidingPhotosEnabled } = useGlobalState()
  const { isUserAuthorized } = useAuthorization()
  const { gallery } = useGallery()
  const share = useSharing()
  const isPrintableProductsEnabled = gallery?.isPrintableProductsEnabled

  function handleShareClick() {
    trackYandexMetrikaAction("click-share-archive-desktop")
    share("gallery", {
      title: galleryName,
      url: `${window.location.origin}${window.location.pathname}`,
      fileURL: cover.sm,
    })
    sharesCountIncrement(galleryId)
  }

  function handleDownloadGalleryClick() {
    downloadGallery()
    trackYandexMetrikaAction("click-header-download-photo-desktop")
  }

  const { $isOnboardingPrintVisible } = useOnboarding()
  const printOnboardingShow =
    typeof window !== "undefined" ? window?.localStorage?.getItem(PRINT_ONBOARDING_PRINT_HINT) : false
  const printOnboardingText = "Вы можете заказать книгу \nс вашими фото."
  const [inViewMargin, setInViewMargin] = React.useState(PRINT_ONDOARDING_IN_VIEW_MARGIN__DESKTOP)

  function handleScrollOnboarding() {
    if (window.pageYOffset >= PRINT_ONDOARDING_IN_VIEW_MARGIN__DESKTOP) {
      setInViewMargin(0)
    } else {
      setInViewMargin(PRINT_ONDOARDING_IN_VIEW_MARGIN__DESKTOP)
    }
  }

  React.useEffect(() => {
    if (isPrintableProductsEnabled && printOnboardingShow !== "completed") {
      window.addEventListener("scroll", handleScrollOnboarding)
    }
  }, [])

  return (
    <nav>
      <ul className={styles["actions"]}>
        {isPrintableProductsEnabled && (
          <li className={styles["action"]}>
            <Tooltip text={i18n.t("common.printPhoto")}>
              <OnboardingHint
                canVisible={$isOnboardingPrintVisible}
                visibleOnView={true}
                inViewMargin={inViewMargin}
                isTransparentClickEnabled={true}
                onTransparentClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
                id={PRINT_ONBOARDING_PRINT_HINT}
                arrowPosition={{ desktop: "right-none", mobile: "none" }}
                offset="20px"
                space="0 -10px 0 0"
                type="circle"
                withImageType={true}
                isClickableContent={true}
                content={<OnboardingPrintBlock description={printOnboardingText} cover={cover?.sm} />}
              >
                <button
                  className={cx(styles["button"], QA_CLASS_NAMES.header.print)}
                  onClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
                >
                  <IconWrapper light={<Printer />} dark={<Printer stroke={COLORS.white} />} />
                </button>
              </OnboardingHint>
            </Tooltip>
          </li>
        )}
        {isDownloadEnabled && (
          <li className={styles["action"]}>
            <Tooltip text={i18n.t("common.downloadAllPhotos")}>
              <button
                className={cx(styles["button"], QA_CLASS_NAMES.header.download)}
                onClick={handleDownloadGalleryClick}
              >
                <IconWrapper
                  light={<Download width="18" height="18" />}
                  dark={<Download stroke={COLORS.white} width="18" height="18" />}
                />
              </button>
            </Tooltip>
          </li>
        )}

        {isFavoritesSectionActivated && <ActionLike galleryId={galleryId} />}

        <li className={styles["action"]}>
          <Tooltip text={i18n.t("common.share")}>
            <button
              className={cx(styles["button"], styles["share-button"], QA_CLASS_NAMES.header.share)}
              onClick={handleShareClick}
            >
              <IconWrapper
                light={<Share width="21" height="17" fill={COLORS.black} />}
                dark={<Share width="21" height="17" fill={COLORS.white} />}
              />
            </button>
          </Tooltip>
        </li>

        {isHidingPhotosEnabled && !isUserAuthorized && (
          <li className={styles["action"]}>
            <AuthClientSignIn
              tooltip={i18n.t("common.clientPrivate")}
              tooltipPosition="down-right"
              buttonClassName={styles["button-auth"]}
              strokeLight={COLORS.black}
              strokeDark={COLORS.white}
              fillLight={COLORS.black}
              fillDark={COLORS.white}
            />
          </li>
        )}
        {isUserAuthorized && (
          <li className={styles["action"]}>
            <AuthClientSignOut
              tooltip={i18n.t("common.signOut")}
              modalText={i18n.t("hidingPhotosModal.signOutDescription")}
              modalApprove={i18n.t("common.signOut")}
              modalCancel={i18n.t("common.cancel")}
              modalPosition="down-right"
              buttonClassName={cx(styles["button-auth"], styles["button-signed"])}
              strokeLight={COLORS.black}
              strokeDark={COLORS.white}
              fillLight={COLORS.black}
              fillDark={COLORS.black}
            />
          </li>
        )}
      </ul>
    </nav>
  )
}

export { DesktopActions }
